
import { Component, Vue, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';

@Component({
  components: {
    SvgIcon,
  },
})
export default class CountrySelection extends Vue {
  link: { name: string } = { name: '' };

  get baseUrl() {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) {
      return 'localhost:8080';
    }
    const env = hostname.split('.')[0]; // Extrae el subdominio (dev, test, etc.)
    return `${env}`;
  }

  get latamUrl() {
    const hostname = window.location.hostname;
    return hostname.includes('localhost')
      ? `http://${this.baseUrl}/inicio`
      : `https://${this.baseUrl}.com/inicio`;
  }

  get europeUrl() {
    const hostname = window.location.hostname;
    return hostname.includes('localhost')
      ? `http://${this.baseUrl}/inicio`
      : `https://${this.baseUrl}.es/inicio`;
  }

  mounted() {
    if (this.isAuthenticated) {
      this.link = { name: 'Dashboard' };
    } else {
      this.link = { name: 'Login' };
    }
  }

  @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
  isAuthenticated!: boolean;
}
